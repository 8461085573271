import {Component} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {ActivatedRoute, Router} from '@angular/router';
import {ApplicationUserService, ManagementService} from './generated';
import {AuthService} from './core/services/auth.service';
import localeCs from '@angular/common/locales/cs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(public oidcSecurityService: OidcSecurityService,
              private authService: AuthService, private activatedRoute: ActivatedRoute,
              private router: Router, private managementService: ManagementService, private applicationUserService: ApplicationUserService) {
  }

  ngOnInit() {
    registerLocaleData(localeCs, 'cs');
    this.oidcSecurityService.checkAuth().subscribe((isAuthenticated) => {
        console.log('app authenticated', isAuthenticated);
        if (isAuthenticated === false) {
          window.localStorage.setItem('return_url', window.location.pathname);
          this.oidcSecurityService.authorize();
        } else {
          this.authService.loadRoles();
          const oldthis = this;
          this.managementService.apiManagementDoIHaveAccessToThisApplicationGet().subscribe(data => {

          }, error => {
            oldthis.applicationUserService.apiApplicationUserCreateMeEmployeeGet().subscribe(() => {
              location.reload();
            });
          });

          if (window.localStorage.getItem('return_url')) {
            this.router.navigateByUrl(window.localStorage.getItem('return_url')!);
            window.localStorage.removeItem('return_url');
          }
        }
      }
    );
  }
}
