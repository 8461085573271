import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private matSnackBar: MatSnackBar) {
  }

  public ok(message: string, duration: number = 5000) {
    this.matSnackBar.open(message, 'ok', {duration, panelClass: 'snack-bar-ok'});
  }

  public error(message: string, duration: number = 7000) {
    this.matSnackBar.open(message, 'ok', {duration, panelClass: 'snack-bar-error'});
  }
}
