<form [formGroup]="form" (ngSubmit)="formSubmit()">
  <div fxLayout="column" fxLayoutAlign="center center">
    <div fxLayout="row" fxLayoutAlign="center center">
      <mat-form-field validationMessage>
        <input formControlName="name" matInput placeholder="Název projektu" name="Project name"
               aria-describedby="Project name" required>
      </mat-form-field>
      <mat-form-field validationMessage>
        <input formControlName="description" matInput placeholder="Popis projektu" name="Project description"
               aria-describedby="Project description">
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <mat-form-field validationMessage>
        <input formControlName="allowedDomains" matInput placeholder="Povolené domény oddělené čárkou (,)"
               name="Allowed domains"
               aria-describedby="Allowed domains">
      </mat-form-field>
      <mat-form-field validationMessage>
    <textarea formControlName="emailTemplate" matInput
              placeholder="Email template proměné v pořadí: jméno, email, subject, zpráva"
              name="Email template"
              aria-describedby="Email template"></textarea>
      </mat-form-field>
    </div>
  </div>
  <input *ngIf="edit && dataForEdit" type="hidden" formControlName="id">
  <button mat-raised-button type="submit">Uložit</button>
</form>
