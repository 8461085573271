import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DtoOutGetMessage, MessageService} from '../../generated';
import {ConfirmationDialogService} from '../../core/services/confirmation-dialog.service';
import {NotificationsService} from '../../core/services/notifications.service';
import {MessageDataService} from '../../services/message-data.service';
import {Role} from '../../core/role';
import {MatDialog} from '@angular/material/dialog';
import {SetPersonToMessageDialogComponent} from '../../dialogs/set-person-to-message-dialog/set-person-to-message-dialog.component';
import {MessagesFilterService} from './messages-filter.service';
import {PageEvent} from '@angular/material/paginator';
import {ConfigRoutes} from 'src/app/core/config/configRoutes';

@Component({
  selector: 'app-project-messages-list',
  templateUrl: './project-messages-list.component.html',
  styleUrls: ['./project-messages-list.component.scss']
})
export class ProjectMessagesListComponent implements OnInit {

  @Input()
  public messages: DtoOutGetMessage[];
  public Role = Role;
  @Output()
  public searchClick = new EventEmitter<void>();
  public ConfigRoutes = ConfigRoutes;

  constructor(private messageService: MessageService, private confirmationDialogService: ConfirmationDialogService,
              private notifficationsService: NotificationsService,
              private messageDataService: MessageDataService, private matDialog: MatDialog,
              public messageFilterService: MessagesFilterService) {
  }


  ngOnInit(): void {
  }


  addPerson(id: number): void {
    const dialog = this.matDialog.open(SetPersonToMessageDialogComponent, {data: {idMessage: id}});
    dialog.afterClosed().subscribe(data => {
      if (data) {
        this.searchClick.emit();
      }
    });

  }

  deleteMessage(id: number): void {
    this.confirmationDialogService.confirmDialog('Opravdu chcete odstranit zprávu?').subscribe(data => {
      if (data === true) {
        this.doDeleteMessage(id);
      }
    });
  }

  doDeleteMessage(id: number): void {
    this.messageService.apiMessageDeleteMessageIdDelete(id).subscribe(data => {
      this.notifficationsService.ok('Správu se podařilo odstranit.');
      this.messageDataService.messageChanged.next();
    }, error => {
      this.notifficationsService.error('Zprávu se nepodařilo smazat.');
    });
  }

  setVisibility(id: number, visibility: boolean): void {
    this.messageService.apiMessageSetMessageVisibilityIdMessagePost(id, visibility).subscribe(data => {
      this.notifficationsService.ok('Stav byl nastaven úspěšně');
      this.messages.find(y => y.id === id).isSeenByMe = visibility;
    }, error => {
      this.notifficationsService.ok('Stav se nepodařilo nastavit');
    });
  }

  setStateToInProgress(id: number): void {
    this.messageService.apiMessageSetMessageToInProgressIdMessageGet(id).subscribe(data => {
      this.notifficationsService.ok('Stav byl posunut do řešení.');
      const message = this.messages.find(y => y.id === id);
      message.inProgress = true;
      message.solved = false;
    }, error => {
      this.notifficationsService.ok('Stav se nepodařilo nastavit');
    });
  }

  setStateToSolved(id: number): void {
    this.messageService.apiMessageSetMessageToSolvedIdMessageGet(id).subscribe(data => {
      this.notifficationsService.ok('Stav byl posunut do dokončeno.');
      const message = this.messages.find(y => y.id === id);
      message.inProgress = false;
      message.solved = true;
    }, error => {
      this.notifficationsService.error('Stav se nepodařilo nastavit');
    });
  }

  onSearchClick(pageEvent: PageEvent = null): void {
    if (pageEvent !== null) {
      this.messageFilterService.pageSize = pageEvent.pageSize;
      this.messageFilterService.pageFrom = pageEvent.pageIndex + 1;
    }
    this.searchClick.emit();
  }
}
