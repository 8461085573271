import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {ConfigRoutes} from './config/configRoutes';
import {UnauthorizedPageComponent} from './unauthorized-page/unauthorized-page.component';

const routes: Routes = [{path: ConfigRoutes.unauthorizedRoute, component: UnauthorizedPageComponent}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule {
}
