import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-redirect-component',
  templateUrl: './redirect-component.component.html',
  styleUrls: ['./redirect-component.component.scss']
})
export class RedirectComponentComponent implements OnInit {

  @Input()
  public redirectUrl: string;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  redirect(event): any {
    event.preventDefault();
    if (event.ctrlKey) {
      return window.open(this.redirectUrl);
    }
    let redirect = ['/'];
    this.redirectUrl.split('/').forEach(item => {
      redirect.push(item);
    });
    this.router.navigate(redirect);
  }

}
