<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Filtry pro zobrazené zprávy
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <mat-form-field>
        <input matInput [(ngModel)]="messagesFilter.subject" placeholder="Podle předmětu" aria-describedby="subject"
               [ngModelOptions]="{standalone:true}">
      </mat-form-field>
      <mat-form-field>
        <input matInput [(ngModel)]="messagesFilter.senderName" placeholder="Jméno odesílajícího"
               aria-describedby="senderName"
               [ngModelOptions]="{standalone:true}">
      </mat-form-field>
      <mat-slide-toggle aria-labelledby="onlyMyMessages" [(ngModel)]="messagesFilter.onlyMyMessages"
                        [ngModelOptions]="{standalone:true}"
                        *hasAccessToView="[Role.NotificationServiceAdmin]">Jen moje zprávy
      </mat-slide-toggle>
      <mat-form-field *hasAccessToView="[Role.NotificationServiceAdmin]" validationMessage>
        <mat-label>Vyberte uživatele</mat-label>
        <mat-select [(ngModel)]="messagesFilter.idSolver">
          <mat-select-trigger>
            <img [src]="getUserUrlImage(messagesFilter.idSolver)" width="20px"
                 height="20px"/>    {{getUserName(messagesFilter.idSolver)}}
          </mat-select-trigger>
          <mat-option [value]="item?.id" *ngFor="let item of users"><img [src]="getUrl(item?.pictureUrl)" width="20px"
                                                                         height="20px"
                                                                         [alt]="item?.lastName"/>{{item?.firstName}} {{item?.lastName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-slide-toggle [(ngModel)]="messagesFilter.inProgress">Zobrazit zprávy v řešení</mat-slide-toggle>
      <mat-slide-toggle [(ngModel)]="messagesFilter.isSolved">Zobrazit dokončené zprávy</mat-slide-toggle>
      <button mat-icon-button color="accent" (click)="refresh()">
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </mat-expansion-panel>
</mat-accordion>
