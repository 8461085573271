import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ConfigRoutes} from './core/config/configRoutes';
import {HomePageComponent} from './pages/home-page/home-page.component';
import {CreateProjectComponent} from './pages/create-project/create-project.component';
import {AuthGuardService} from './core/services/guard/auth-guard.service';
import {Role} from './core/role';
import {EditProjectComponent} from './pages/edit-project/edit-project.component';
import {DetailProjectComponent} from './pages/detail-project/detail-project.component';
import {EmployeesPageComponent} from './pages/employees-page/employees-page.component';
import {MessageDetailPageComponent} from './pages/message-detail-page/message-detail-page.component';
import {EmployeeListComponent} from './lists/employee-list/employee-list.component';
import {EmployeePageComponent} from './pages/employee-page/employee-page.component';

const routes: Routes = [
  {path: '', component: HomePageComponent, pathMatch: 'full'},
  {
    path: ConfigRoutes.createProjectPage,
    component: CreateProjectComponent,
    canActivate: [AuthGuardService],
    data: {roles: [Role.NotificationServiceAdmin]}
  },
  {
    path: ConfigRoutes.editProjectPage + '/:id',
    component: EditProjectComponent,
    canActivate: [AuthGuardService],
    data: {roles: [Role.NotificationServiceAdmin]}
  },
  {
    path: ConfigRoutes.projectPage + '/:id',
    component: DetailProjectComponent,
    canActivate: [AuthGuardService],
    data: {roles: [Role.NotificationServiceUser]}
  },
  {
    path: ConfigRoutes.messageDetailProject + '/:id',
    component: MessageDetailPageComponent,
    canActivate: [AuthGuardService],
    data: {roles: [Role.NotificationServiceUser]}
  },
  {
    path: ConfigRoutes.employeePage,
    component: EmployeesPageComponent,
    canActivate: [AuthGuardService],
    data: {roles: [Role.NotificationServiceAdmin]}
  },
  {
    path: ConfigRoutes.employeeDetailPage + '/:id',
    component: EmployeePageComponent,
    canActivate: [AuthGuardService],
    data: {roles: [Role.NotificationServiceAdmin]}
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
