import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MessagesFilterService {
  public pageFrom = 1;
  public pageSize = 10;
  public onlyMyMessages = true;
  public idSolver: number = null;
  public senderName: string = null;
  public subject: string = null;
  public size: number = null;
  public inProgress = false;
  public isSolved = false;

  constructor() {
  }
}
