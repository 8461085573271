import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageDataService {
  public messageChanged = new Subject<void>();
  constructor() { }
}
