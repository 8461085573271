import {FormGroup} from '@angular/forms';
import {ConfigRoutes} from '../config/configRoutes';
import {Input, OnInit, Directive, Component, Output, EventEmitter} from '@angular/core';

// T=´createFormOutput value  E= editFormValueOutput  D= EditForm input
@Component({template: ''})
export abstract class BaseEditCreateFormComponent<T, E, D> implements OnInit {
  get dataForEdit(): D {
    return this._dataForEdit;
  }

  @Input()
  set dataForEdit(value: D) {
    this._dataForEdit = value;
    this.createForm();
  }

  get btnDisabled(): boolean {
    return this._btnDisabled;
  }

  set btnDisabled(value: boolean) {
    this._btnDisabled = value;
    this.btnDisabledChange.emit(value);
  }

  @Input()
  public edit: boolean;

  private _dataForEdit: D;
  @Output()
  public btnDisabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public form: FormGroup;
  private _btnDisabled: boolean;
  public ConfigRoutes = ConfigRoutes;

  protected constructor() {
  }

  ngOnInit(): void {
    this.createForm();
  }

  public abstract createEmptyInputObject(): D;

  public abstract createForm(): void;

  public formSubmit() {
    if (this.form.valid === true) {
      this._btnDisabled = true;
      this.requestInFormSubmit();
    }
  }


  public getFormValue(raw?: boolean) {
    let value = Object.assign({}, raw ? this.form.getRawValue() : this.form.value); // raw value jsou pro disabled

    return value;
  }

  public abstract requestInFormSubmit();

  public abstract getDataCreate(): T;

  public abstract getDataEdit(): E;
}
