// E= editFormValueOutput  D= EditForm input
import {Input, OnInit, Directive, Component, Output, EventEmitter} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ConfigRoutes} from '../config/configRoutes';

//E= editFormValueOutput  D= EditForm input
@Component({template: ''})
export abstract class BaseEditFormComponent<E, D> implements OnInit {
  get btnDisabled(): boolean {
    return this._btnDisabled;
  }

  set btnDisabled(value: boolean) {
    this._btnDisabled = value;
    this.btnDisabledChange.emit(value);
  }

  public form: FormGroup;
  @Output()
  public btnDisabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _btnDisabled: boolean = false;
  public ConfigRoutes = ConfigRoutes;
  @Input()
  public dataForEdit: D;

  ngOnInit(): void {
    this.createForm();
  }

  public abstract createForm();

  public formSubmit() {
    if (this.form.valid === true) {
      this._btnDisabled = true;
      this.requestInFormSubmit();
    }
  }

  public getFormValue(raw?: boolean) {
    let value = Object.assign({}, raw ? this.form.getRawValue() : this.form.value); // raw value jsou pro disabled

    return value;
  }

  public abstract requestInFormSubmit();

  public abstract getDataEdit(): E;
}
