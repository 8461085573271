export class ConfigRoutes {
  public static readonly home = '/';
  public static readonly projectPage = 'project';
  public static readonly editProjectPage = 'edit-project';
  public static readonly createProjectPage = 'create-project';
  public static readonly employeePage = 'employees';
  public static readonly employeeDetailPage = 'employee';
  public static readonly messageDetailProject = 'message-detail';
  public static readonly unauthorizedRoute = 'unauthorized';


}
