<form [formGroup]="form" (ngSubmit)="submitForm()">
  <mat-form-field *ngIf="projects" validationMessage>
    <mat-label>Favorite animal</mat-label>
    <mat-select  formControlName="idProject" required>
      <mat-option>--</mat-option>
      <mat-option *ngFor="let project of projects" [value]="project.id">
        <span [matTooltip]="project?.description">{{project.name}}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <ng-content></ng-content>
</form>
