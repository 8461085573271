import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {BaseEditFormComponent} from '../../core/base-classes/base-edit-form.component';
import {DtoInUpdateProjectSettings, DtoOutProjectSettings, ProjectService} from '../../generated';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationsService} from '../../core/services/notifications.service';

@Component({
  selector: 'app-edit-project-settings-form',
  templateUrl: './edit-project-settings-form.component.html',
  styleUrls: ['./edit-project-settings-form.component.scss']
})
export class EditProjectSettingsFormComponent extends BaseEditFormComponent<DtoInUpdateProjectSettings, DtoOutProjectSettings> implements OnInit {


  get projectSettings(): DtoOutProjectSettings {
    return this._projectSettings;
  }

  @Input()
  set projectSettings(value: DtoOutProjectSettings) {
    this._projectSettings = value;
    this.createForm();
  }

  @Output()
  public formSubmited = new EventEmitter();

  private _projectSettings: DtoOutProjectSettings;

  constructor(private notificationService: NotificationsService, private projectService: ProjectService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  createForm(): void {
    this.form = new FormGroup({
      idProject: new FormControl(this._projectSettings?.idProject),
      contactEmails: new FormControl(this._projectSettings?.contactEmails, [Validators.required, Validators.pattern('^([a-zA-Z0-9.!#$%&\'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*,?)+$')]),
      wantSendEmails: new FormControl(this._projectSettings?.wantSendEmails)
    });
  }

  getDataEdit(): DtoInUpdateProjectSettings {
    return this.form.getRawValue() as DtoInUpdateProjectSettings;
  }

  requestInFormSubmit(): void {
    this.projectService.apiProjectUpdateProjectSettingsPatch(this.getDataEdit()).subscribe(data => {
      this.btnDisabled = false;
      this.notificationService.ok('Nastavení na projektu proběhlo úspěšně');
      this.formSubmited.emit();
    }, error => {
      this.btnDisabled = false;
      this.notificationService.error('Nastavení na projektu se nezdařilo');
    });
  }
}
