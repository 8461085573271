import {ApplicationInitStatus, Injectable} from '@angular/core';
import {ApplicationUserService, DtoOutAllApplicationUsers, DtoOutUsers, ProjectService} from '../generated';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  public users: Map<number, DtoOutAllApplicationUsers[]> = new Map<number, DtoOutAllApplicationUsers[]>();

  constructor(private projectService: ProjectService) {
  }

  getUsers(projectId: number): Observable<Array<DtoOutAllApplicationUsers>> {
    return new Observable<Array<DtoOutAllApplicationUsers>>(observer => {
      const oldthis = this;
      const userPerProejct = this.users.get(projectId);
      if (!userPerProejct) {
        this.projectService.apiProjectGetUsersByProjectIdProjectIdGet(projectId).subscribe(data => {
          oldthis.users.set(projectId, data.data);
          observer.next(data.data);
          observer.complete();
        });
      } else {
        observer.next(userPerProejct);
        observer.complete();
      }
    });
  }

  getUserName(idProject: number, id: number): string {
    const user = this.users.get(idProject).find(x => x.id === id);
    return user.firstName + ' ' + user.lastName;
  }

  getUserUrlImage(idProject: number, id: number): string {
    return this.getUrl(this.users.get(idProject).find(x => x.id === id).pictureUrl);
  }

  getUrl(url: string): string {
    return new URL(url).toString();
  }
}
