<app-messages-filter (searchClick)="onSearchClick()"></app-messages-filter>
<div *ngFor="let item of messages">
  <mat-card>
    <mat-card-header>
      <app-redirect-component [redirectUrl]="ConfigRoutes.messageDetailProject+'/'+item?.id">
        <mat-card-title class="clickable">
          od: {{item?.firstname}} {{item?.lastname}} {{item?.email}}
          <mat-icon *ngIf="item?.isSeenByMe">visibility</mat-icon>
          <mat-icon *ngIf="!item?.isSeenByMe">visibility_off</mat-icon>
          <mat-icon *ngIf="item?.inProgress">autorenew</mat-icon>
          <mat-icon *ngIf="item?.solved">done</mat-icon>
          <span *ngIf="item?.selectedPerson!==null">
        přiřazeno: {{item?.selectedPerson?.firstName}} {{item?.selectedPerson?.lastName}} <img width="30px"
                                                                                               height="30px"
                                                                                               [src]="item?.selectedPerson?.pictureUrl"/></span>
        </mat-card-title>
      </app-redirect-component>
      <span class="menu"><button mat-icon-button [matMenuTriggerFor]="menuList"><mat-icon>more_vert</mat-icon></button></span>
    </mat-card-header>
    <mat-card-content>
      {{item?.subject}}
    </mat-card-content>
  </mat-card>
  <mat-menu #menuList="matMenu">
    <button *hasAccessToView="[Role.NotificationServiceAdmin]" mat-menu-item (click)="addPerson(item?.id)">
      <mat-icon>perm_identity</mat-icon>
      <span>Přiřadit osobu</span>
    </button>
    <button *hasAccessToView="[Role.NotificationServiceAdmin]" mat-menu-item (click)="deleteMessage(item?.id)">
      <mat-icon color="warn">delete</mat-icon>
      <span>Odstranit</span>
    </button>
    <button *ngIf="item?.isSeenByMe===true" mat-menu-item (click)="setVisibility(item?.id,false)">
      <mat-icon>visibility_off</mat-icon>
      <span>Označit jako neviděná</span>
    </button>
    <button *ngIf="item?.isSeenByMe===false" mat-menu-item (click)="setVisibility(item?.id,true)">
      <mat-icon>visibility</mat-icon>
      <span>Označit jako viděno</span>
    </button>
    <button *ngIf="item?.inProgress===false" mat-menu-item (click)="setStateToInProgress(item?.id)">
      <mat-icon>autorenew</mat-icon>
      <span>Posunout do stavu: V řešení</span>
    </button>
    <button *ngIf="item?.solved===false" mat-menu-item (click)="setStateToSolved(item?.id)">
      <mat-icon>done</mat-icon>
      <span>Posunout do stavu: Vyřešeno</span>
    </button>
  </mat-menu>

</div>
<mat-paginator [length]="messageFilterService.size"
               [pageSize]="messageFilterService.pageSize"
               [pageSizeOptions]="[5,10,25,50]"
               (page)="onSearchClick($event)">
</mat-paginator>
