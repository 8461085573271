import {
  AfterViewChecked,
  AfterViewInit,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef, HostListener, NgZone,
  ViewContainerRef
} from '@angular/core';
import {ValidationErrorComponent} from './validation-error/validation-error.component';
import {MatFormField} from '@angular/material/form-field';

@Directive({
  selector: '[validationMessage]'
})
export class ValidationDirective implements AfterViewInit, AfterViewChecked {

  constructor(private viewContainerRef: ViewContainerRef, private resolver: ComponentFactoryResolver,
              private formField: MatFormField, private elRef: ElementRef, private ngZone: NgZone) {
  }

  @HostListener('focusin', ['$event']) onClick($event) {
    this.onChange($event);
  }

  ref: ComponentRef<ValidationErrorComponent>;

  private error: string;


  private onChange(res: any) {
    if (this.formField._control.ngControl!.invalid!) {
      this.setError(this.formField._control.ngControl!.errors!);
    } else {
      this.setError('');
    }
  }


  private setError(key: any) { //TODO dodělat pomalu všechny validace
    // naplnit error
    if (key['required']) {
      this.error = 'Pole je povinné';
    } else if (key['minlength']) {
      this.error = 'Minimální délka je: ' + key['minlength'].requiredLength;
    } else if (key['maxlength']) {
      this.error = 'Maximální délka je: ' + key['maxlength'].requiredLength;
    } else if (key['min']) {
      this.error = 'Minimální hodnota je: ' + key['min'].min;
    } else if (key['max']) {
      this.error = 'Maximální hodnota je: ' + key['max'].max;
    } else if (key['email']) {
      this.error = 'Zadejte email ve správném tvaru';
    } else if (key['date-error-range']) {
      this.error = 'Minimální datum je ' + new Date(key['date-error-range']['minimumDate']).toLocaleString() + '. Maximální datum je ' + new Date(key['date-error-range']['maximumDate']).toLocaleString()
        + '.';
    } else if (key['pattern']) {
      this.error = 'Požadovaný formát: ' + key['pattern']['requiredPattern'];
    } else if (key['maxSize']) {
      this.error = 'Obrázek je příliš velký, vyberte menší.';
    } else {
      this.error = '';
    }
    if (!this.ref) {
      const factory = this.resolver.resolveComponentFactory(ValidationErrorComponent);
      this.ref = this.viewContainerRef.createComponent(factory);
      this.elRef.nativeElement.querySelector('.mat-form-field-subscript-wrapper').appendChild(this.ref.location.nativeElement);
    }

    this.ref.instance.error = this.error;
  }

  ngAfterViewInit(): void {
    this.ngZone.run(() => {
      const oldthis = this;
      this.formField._control.ngControl!.statusChanges!.subscribe(res => this.onChange(res));
      this.formField._control.stateChanges.subscribe(res => {
        oldthis.onChange(res);
      });
    });

  }

  ngAfterViewChecked(): void {
    if (this.ref) {
      this.ngZone.run(() => {
        this.elRef.nativeElement.querySelector('.mat-form-field-subscript-wrapper').appendChild(this.ref.location.nativeElement);
      });
    }
  }
}
