<http-spinner>
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{project?.name}}</mat-card-title>
      <mat-card-subtitle>{{project?.description}}</mat-card-subtitle>
      <span class="settings"><button mat-icon-button (click)="openSettings()"><mat-icon>settings</mat-icon></button></span>
      <span class="menu" *hasAccessToView="[Role.NotificationServiceAdmin]"><button mat-icon-button
                                                                                    [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button></span>
    </mat-card-header>
    <mat-card-content>
      Povolené domény: <span *ngFor="let item of project?.allowedDomains.split(',')">{{item}} &nbsp;</span>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Zprávy</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <app-project-messages-list (searchClick)="refreshMessages()" [messages]="messages"></app-project-messages-list>
    </mat-card-content>
  </mat-card>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="editProject()">
      <mat-icon>create</mat-icon>
      <span>Upravit</span>
    </button>
    <button mat-menu-item (click)="deleteProject()">
      <mat-icon color="warn">delete</mat-icon>
      <span>Smazat</span>
    </button>
  </mat-menu>
</http-spinner>
