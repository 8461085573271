import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {DtoOutAllApplicationUsers} from '../../generated';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {ConfigRoutes} from '../../core/config/configRoutes';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss']
})
export class EmployeeListComponent implements OnInit {
  displayedColumns: string[] = ['firstName', 'lastName', 'email', 'picture'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dataSource: MatTableDataSource<DtoOutAllApplicationUsers>;

  get data(): Array<DtoOutAllApplicationUsers> {
    return this._data;
  }

  @Input()
  set data(value: Array<DtoOutAllApplicationUsers>) {
    this._data = value;
    if (value) {
      this.dataSource = new MatTableDataSource([]);
      this.dataSource.data = value;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  private _data: Array<DtoOutAllApplicationUsers>;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getUrl(url: string): string {
    return new URL(url).toString();
  }

  onRowCLick(id: number): void {
    this.router.navigate([ConfigRoutes.employeeDetailPage, id]);
  }

}
