import {Component, OnInit} from '@angular/core';
import {BaseEditCreateFormComponent} from '../../core/base-classes/base-edit-create-form.component';
import {DtoInCreateProject, DtoInUpdateProject, DtoOutProject, ProjectService} from '../../generated';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationsService} from '../../core/services/notifications.service';
import {Router} from '@angular/router';
import {NavService} from '../../core/structure/content/menu-list-item/nav.service';

@Component({
  selector: 'app-create-edit-project-form',
  templateUrl: './create-edit-project-form.component.html',
  styleUrls: ['./create-edit-project-form.component.scss']
})
// tslint:disable-next-line:max-line-length
export class CreateEditProjectFormComponent extends BaseEditCreateFormComponent<DtoInCreateProject, DtoInUpdateProject, DtoOutProject> implements OnInit {

  // tslint:disable-next-line:max-line-length
  constructor(private projectService: ProjectService, private notificationService: NotificationsService, private router: Router, private navService: NavService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  createEmptyInputObject(): DtoOutProject {
    return {
      name: '',
      allowedDomains: '',
      emailTemplate: '',
      description: '',
      id: null,
      guid: ''
    };
  }

  createForm(): void {
    if (this.edit && this.dataForEdit) {
      const obj = this.dataForEdit;
      this.form = new FormGroup({
        id: new FormControl(obj.id),
        name: new FormControl(obj.name, [Validators.required, Validators.minLength(2), Validators.maxLength(20)]),
        description: new FormControl(obj.description, [Validators.minLength(2), Validators.maxLength(300)]),
        // tslint:disable-next-line:max-line-length
        allowedDomains: new FormControl(obj.allowedDomains, [Validators.pattern('^(([\\w-]+\\.)*[\\w\\-]+\\.\\w{2,10},?)+$'), Validators.required]),
        emailTemplate: new FormControl(obj.emailTemplate, [Validators.required])
      });

    } else {
      const obj = this.createEmptyInputObject();
      this.form = new FormGroup({
        name: new FormControl(obj.name, [Validators.required, Validators.minLength(2), Validators.maxLength(20)]),
        description: new FormControl(obj.description, [Validators.minLength(2), Validators.maxLength(300)]),
        allowedDomains: new FormControl(obj.allowedDomains, [Validators.pattern('^(([\\w-]+\\.)*[\\w\\-]+\\.\\w{2,10},?)+$')]),
        emailTemplate: new FormControl(obj.emailTemplate, [Validators.required])
      });
    }
  }

  getDataCreate(): DtoInCreateProject {
    return this.form.getRawValue() as DtoInCreateProject;
  }

  getDataEdit(): DtoInUpdateProject {
    return this.form.getRawValue() as DtoInUpdateProject;
  }

  requestInFormSubmit(): void {
    if (this.form.valid) {
      if (this.edit) {
        this.projectService.apiProjectUpdateProjectPatch(this.getDataEdit()).subscribe(data => {
          this.btnDisabled = false;
          this.notificationService.ok('Úprava projektu proběhla úspěšně');
          this.navService.projectsChanged.next();
          this.router.navigate([this.ConfigRoutes.home]);
        }, error => {
          this.btnDisabled = false;
          this.notificationService.error('Úprava projektu se nezdařila');
        });
      } else {
        this.projectService.apiProjectCreateProjectPost(this.getDataCreate()).subscribe(data => {
          this.btnDisabled = false;
          this.notificationService.ok('Vytvoření projektu proběhlo úspěšně');
          this.navService.projectsChanged.next();
          this.router.navigate([this.ConfigRoutes.home]);
        }, error => {
          this.btnDisabled = false;
          this.notificationService.error('Vytvoření projektu se nezdažilo');
        });
      }
    }
  }


}
