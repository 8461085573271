import {Component, Inject, OnInit} from '@angular/core';
import {DtoOutPersonsSelectedForMethods, DtoOutUsers, ProjectService} from '../../generated';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UsersService} from '../../services/users.service';
import {ApplicationInfoService} from '../../services/application-info.service';

@Component({
  selector: 'app-set-person-to-message-dialog',
  templateUrl: './set-person-to-message-dialog.component.html',
  styleUrls: ['./set-person-to-message-dialog.component.scss']
})
export class SetPersonToMessageDialogComponent implements OnInit {
  public users: Array<DtoOutUsers> = [];

  constructor(private projectService: ProjectService, private usersService: UsersService, public dialogRef: MatDialogRef<SetPersonToMessageDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { idMessage: number }, private applicationInfoService: ApplicationInfoService) {
  }

  ngOnInit(): void {
    this.usersService.getUsers(this.applicationInfoService.selectedIdProject).subscribe(data => {
      this.users = data;
    });
  }

  close(change: boolean = false): void {
    this.dialogRef.close(change);
  }
}
