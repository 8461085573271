/**
 * Notification service
 * Notification service for larvasystems products contact forms
 *
 * OpenAPI spec version: 1
 * Contact: vojtech.stor@larvasystems.cz
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DtoInAddUserToProject } from '../model/dtoInAddUserToProject';
import { DtoInCreateProject } from '../model/dtoInCreateProject';
import { DtoInUpdateProject } from '../model/dtoInUpdateProject';
import { DtoInUpdateProjectSettings } from '../model/dtoInUpdateProjectSettings';
import { DtoOutProjectListListMapResponseObject } from '../model/dtoOutProjectListListMapResponseObject';
import { DtoOutProjectMapResponseObject } from '../model/dtoOutProjectMapResponseObject';
import { DtoOutProjectSettingsMapResponseObject } from '../model/dtoOutProjectSettingsMapResponseObject';
import { DtoOutUsersListMapResponseObject } from '../model/dtoOutUsersListMapResponseObject';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ProjectService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectAddUserToProjectPost(body?: DtoInAddUserToProject, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiProjectAddUserToProjectPost(body?: DtoInAddUserToProject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiProjectAddUserToProjectPost(body?: DtoInAddUserToProject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiProjectAddUserToProjectPost(body?: DtoInAddUserToProject, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Project/AddUserToProject`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectCreateProjectPost(body?: DtoInCreateProject, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiProjectCreateProjectPost(body?: DtoInCreateProject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiProjectCreateProjectPost(body?: DtoInCreateProject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiProjectCreateProjectPost(body?: DtoInCreateProject, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Project/CreateProject`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectDeleteProjectIdPost(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiProjectDeleteProjectIdPost(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiProjectDeleteProjectIdPost(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiProjectDeleteProjectIdPost(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiProjectDeleteProjectIdPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Project/DeleteProject/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectGetMyProjectsGet(observe?: 'body', reportProgress?: boolean): Observable<DtoOutProjectListListMapResponseObject>;
    public apiProjectGetMyProjectsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DtoOutProjectListListMapResponseObject>>;
    public apiProjectGetMyProjectsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DtoOutProjectListListMapResponseObject>>;
    public apiProjectGetMyProjectsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DtoOutProjectListListMapResponseObject>('get',`${this.basePath}/api/Project/GetMyProjects`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectGetProjectIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<DtoOutProjectMapResponseObject>;
    public apiProjectGetProjectIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DtoOutProjectMapResponseObject>>;
    public apiProjectGetProjectIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DtoOutProjectMapResponseObject>>;
    public apiProjectGetProjectIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiProjectGetProjectIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DtoOutProjectMapResponseObject>('get',`${this.basePath}/api/Project/GetProject/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectGetProjectSettingsIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<DtoOutProjectSettingsMapResponseObject>;
    public apiProjectGetProjectSettingsIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DtoOutProjectSettingsMapResponseObject>>;
    public apiProjectGetProjectSettingsIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DtoOutProjectSettingsMapResponseObject>>;
    public apiProjectGetProjectSettingsIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiProjectGetProjectSettingsIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DtoOutProjectSettingsMapResponseObject>('get',`${this.basePath}/api/Project/GetProjectSettings/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectGetProjectsGet(observe?: 'body', reportProgress?: boolean): Observable<DtoOutProjectListListMapResponseObject>;
    public apiProjectGetProjectsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DtoOutProjectListListMapResponseObject>>;
    public apiProjectGetProjectsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DtoOutProjectListListMapResponseObject>>;
    public apiProjectGetProjectsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DtoOutProjectListListMapResponseObject>('get',`${this.basePath}/api/Project/GetProjects`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param messageId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectGetUsersByMessageIdMessageIdGet(messageId: number, observe?: 'body', reportProgress?: boolean): Observable<DtoOutUsersListMapResponseObject>;
    public apiProjectGetUsersByMessageIdMessageIdGet(messageId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DtoOutUsersListMapResponseObject>>;
    public apiProjectGetUsersByMessageIdMessageIdGet(messageId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DtoOutUsersListMapResponseObject>>;
    public apiProjectGetUsersByMessageIdMessageIdGet(messageId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (messageId === null || messageId === undefined) {
            throw new Error('Required parameter messageId was null or undefined when calling apiProjectGetUsersByMessageIdMessageIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DtoOutUsersListMapResponseObject>('get',`${this.basePath}/api/Project/GetUsersByMessageId/${encodeURIComponent(String(messageId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectGetUsersByProjectIdProjectIdGet(projectId: number, observe?: 'body', reportProgress?: boolean): Observable<DtoOutUsersListMapResponseObject>;
    public apiProjectGetUsersByProjectIdProjectIdGet(projectId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DtoOutUsersListMapResponseObject>>;
    public apiProjectGetUsersByProjectIdProjectIdGet(projectId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DtoOutUsersListMapResponseObject>>;
    public apiProjectGetUsersByProjectIdProjectIdGet(projectId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling apiProjectGetUsersByProjectIdProjectIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DtoOutUsersListMapResponseObject>('get',`${this.basePath}/api/Project/GetUsersByProjectId/${encodeURIComponent(String(projectId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param idProject 
     * @param idUser 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectRemoveUserFromProjectIdProjectIdUserDelete(idProject: number, idUser: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiProjectRemoveUserFromProjectIdProjectIdUserDelete(idProject: number, idUser: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiProjectRemoveUserFromProjectIdProjectIdUserDelete(idProject: number, idUser: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiProjectRemoveUserFromProjectIdProjectIdUserDelete(idProject: number, idUser: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idProject === null || idProject === undefined) {
            throw new Error('Required parameter idProject was null or undefined when calling apiProjectRemoveUserFromProjectIdProjectIdUserDelete.');
        }

        if (idUser === null || idUser === undefined) {
            throw new Error('Required parameter idUser was null or undefined when calling apiProjectRemoveUserFromProjectIdProjectIdUserDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/Project/RemoveUserFromProject/${encodeURIComponent(String(idProject))}-${encodeURIComponent(String(idUser))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectUpdateProjectPatch(body?: DtoInUpdateProject, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiProjectUpdateProjectPatch(body?: DtoInUpdateProject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiProjectUpdateProjectPatch(body?: DtoInUpdateProject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiProjectUpdateProjectPatch(body?: DtoInUpdateProject, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('patch',`${this.basePath}/api/Project/UpdateProject`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectUpdateProjectSettingsPatch(body?: DtoInUpdateProjectSettings, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiProjectUpdateProjectSettingsPatch(body?: DtoInUpdateProjectSettings, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiProjectUpdateProjectSettingsPatch(body?: DtoInUpdateProjectSettings, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiProjectUpdateProjectSettingsPatch(body?: DtoInUpdateProjectSettings, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('patch',`${this.basePath}/api/Project/UpdateProjectSettings`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
