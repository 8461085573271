import {Component, OnInit, EventEmitter, Output} from '@angular/core';
import {Role} from 'src/app/core/role';
import {MessagesFilterService} from '../messages-filter.service';
import {DtoOutAllApplicationUsers} from '../../../generated';
import {UsersService} from '../../../services/users.service';
import {ApplicationInfoService} from '../../../services/application-info.service';
import {AuthService} from '../../../core/services/auth.service';

@Component({
  selector: 'app-messages-filter',
  templateUrl: './messages-filter.component.html',
  styleUrls: ['./messages-filter.component.scss']
})
export class MessagesFilterComponent implements OnInit {
  public Role = Role;
  public users: DtoOutAllApplicationUsers[];
  @Output()
  public searchClick = new EventEmitter<void>();

  constructor(public messagesFilter: MessagesFilterService,
              private usersService: UsersService,
              private applicationInfoService: ApplicationInfoService,
              private authenticationService: AuthService) {
  }

  ngOnInit(): void {
    this.authenticationService.hasRole(Role.NotificationServiceAdmin).subscribe(data => {
      if (data) {
        this.loadUsers();
      }
    });

  }

  loadUsers() {
    this.usersService.getUsers(this.applicationInfoService.selectedIdProject).subscribe(data => {
      this.users = data;
    });
  }

  getUserName(id: number): string {
    if (id && this.users) {
      const user = this.users?.find(x => x.id === id);
      return user.firstName + ' ' + user.lastName;
    }
    return null;
  }

  getUserUrlImage(id: number): string {
    if (id && this.users) {
      return this.getUrl(this.users?.find(x => x.id === id).pictureUrl);
    }
    return null;
  }

  getUrl(url: string): string {
    return new URL(url).toString();
  }

  refresh(): void {
    this.searchClick.emit();
  }
}
