import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {DtoInAddUserToProject, DtoOutProjectList, ProjectService} from '../../generated';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationsService} from '../../core/services/notifications.service';

@Component({
  selector: 'app-add-user-to-project-form',
  templateUrl: './add-user-to-project-form.component.html',
  styleUrls: ['./add-user-to-project-form.component.scss']
})
export class AddUserToProjectFormComponent implements OnInit {
  get btnDisabled(): boolean {
    return this._btnDisabled;
  }

  set btnDisabled(value: boolean) {
    this._btnDisabled = value;
    this.btnDisabledChange.emit(value);
  }
  public projects: DtoOutProjectList[];
  public form: FormGroup;
  @Output()
  public formSubmited = new EventEmitter();
  @Output()
  public btnDisabledChange = new EventEmitter<boolean>();
  private _btnDisabled = false;
  @Input()
  public idUser: number;

  constructor(private projectService: ProjectService, private notificationService: NotificationsService) {
  }

  ngOnInit(): void {
    this.createForm();
    this.projectService.apiProjectGetProjectsGet().subscribe(data => {
      this.projects = data.data;
    });
  }

  createForm(): void {
    this.form = new FormGroup(
      {
        'idProject': new FormControl(null, [Validators.required])
      }
    );
  }

  submitForm() {
    if (this.form.valid) {
      this.btnDisabled = true;
      let body: DtoInAddUserToProject = {idApplicationUser: this.idUser, idProject: this.form.controls.idProject.value};
      this.projectService.apiProjectAddUserToProjectPost(body).subscribe(data => {
        this.btnDisabled = false;
        this.formSubmited.emit(this.form.controls.idProject.value);
        this.notificationService.ok('Uživatel byl přidán do projektu');
      }, error => {
        this.btnDisabled = false;
        this.notificationService.error('Uživatele se nepodařilo přidat do projektu');
      });

    }
  }
}
