import {Component, OnInit} from '@angular/core';
import {BaseInfoPageComponent} from '../../core/base-classes/base-info-page.component';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router, Routes} from '@angular/router';
import {ApplicationUserService, DtoOutApplicationUserInfo, ProjectService} from '../../generated';
import {ConfigRoutes} from '../../core/config/configRoutes';
import {ConfirmationDialogService} from '../../core/services/confirmation-dialog.service';
import {NotificationsService} from '../../core/services/notifications.service';
import {MatDialog} from '@angular/material/dialog';
import {AddUserToProjectDialogComponent} from '../../dialogs/add-user-to-project-dialog/add-user-to-project-dialog.component';

@Component({
  selector: 'app-employee-page',
  templateUrl: './employee-page.component.html',
  styleUrls: ['./employee-page.component.scss']
})
export class EmployeePageComponent extends BaseInfoPageComponent implements OnInit {

  public user: DtoOutApplicationUserInfo;

  constructor(private title: Title, private activatedRoute: ActivatedRoute,
              private applicationUserService: ApplicationUserService, private router: Router,
              private confirmationDialogService: ConfirmationDialogService, private projectService: ProjectService,
              private notificationService: NotificationsService, private matDialog: MatDialog) {
    super(title, 'Detail uživatele', activatedRoute);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getItem(id: number): void {
    this.applicationUserService.apiApplicationUserGetInfoAboutUserIdGet(id).subscribe(data => {
      this.user = data.data;
    });
  }

  redirectToProject(id: number): void {
    this.router.navigate([ConfigRoutes.projectPage, id]);
  }

  getUrl(url: string): string {
    if (url) {
      return new URL(url).toString();
    }
    return '';
  }

  removeUserFromProject(id: number): void {
    this.confirmationDialogService.confirmDialog('Opravdu si přejete odebrat uživatele z projekut?').subscribe(data => {
      if (data) {
        this.callRemoveUserFromProject(id);
      }
    });
  }

  callRemoveUserFromProject(id: number): void {
    this.projectService.apiProjectRemoveUserFromProjectIdProjectIdUserDelete(id, this.user?.id).subscribe(data => {
      this.notificationService.ok('Uživatel byl smazán z projektu');
      this.user.projectLists = this.user.projectLists.filter(x => x.id !== id);
    }, error => {
      this.notificationService.error('Smazání uživatele z projektu se nezdařilo.');
    });
  }

  addUserToProject(): void {
    const dialogRef = this.matDialog.open(AddUserToProjectDialogComponent, {
      data: {
        idUser: this.user.id,
        firstName: this.user.firstName,
        lastName: this.user.lastName
      }
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.getItem(this.searchedDataId);
      }
    });
  }
}
