import {Component, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-validation-error',
  templateUrl: './validation-error.component.html',
  styleUrls: ['./validation-error.component.scss'],
  animations: [
    trigger('animation', [
      state('show', style({
        opacity: 1,
      })),
      state('hide', style({
        opacity: 0,
        transform: 'translateY(-1rem)'
      })),
      transition('show => hide', animate('200ms ease-out')),
      transition('* => show', animate('200ms ease-in'))

    ]),
  ]
})
export class ValidationErrorComponent {

  _error: any;
  _state: any;
  message;

  @Input()
  set error(value) {
    if (value && !this.message) {
      this.message = value;
      this._state = 'hide';
      setTimeout(() => {
        this._state = 'show';
      });
    } else {
      this._error = value;
      this.message = value;
      this._state = value ? 'show' : 'hide';
    }
  }

}
