import {Configuration} from '../../generated';
// @ts-ignore
import config from '../../../assets/config/config.json';


export class ConfigurationService {
  constructor() {
  }

  static getConfigForGenerated(): Configuration {
    const configuration = new Configuration();
    configuration.basePath = ConfigurationService.get('apiUrl');
    configuration.accessToken = null;
    return configuration;
  }

  // Gets a value of specified property in the configuration file
  static get(key: any) {
    return config[key];
  }
}
