import {Component, OnInit} from '@angular/core';
import {BaseInfoPageComponent} from '../../core/base-classes/base-info-page.component';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {DtoOutProject, ProjectService} from '../../generated';
import {ConfirmationDialogService} from '../../core/services/confirmation-dialog.service';
import {NavService} from '../../core/structure/content/menu-list-item/nav.service';
import {NotificationsService} from '../../core/services/notifications.service';

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss']
})
export class EditProjectComponent extends BaseInfoPageComponent implements OnInit {
  public project: DtoOutProject;

  constructor(private title: Title, private activatedRoute: ActivatedRoute,
              private projectService: ProjectService) {
    super(title, 'Úprava projektu', activatedRoute);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getItem(id: number): void {
    this.projectService.apiProjectGetProjectIdGet(id).subscribe(data => {
      this.project = data.data;
      this.title.setTitle('NotificationService Úprava projektu - ' + this.project.name);
    });
  }

}
