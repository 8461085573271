<mat-sidenav-container *ngIf="(oidcSecurityService.isAuthenticated$|async)" #navcontainer
                       class="sidenav-container all-wrap">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
               [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
               [mode]="(isHandset$ | async) ? 'over' : 'side'"
               [opened]="(isHandset$ | async) === false">
    <mat-toolbar><span>{{ fullName | async}}</span></mat-toolbar>
    <mat-nav-list>
      <app-menu-list-item *ngFor="let item of navItems" [item]="item"></app-menu-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="page-wrap">
    <app-header (menuToggleClick)="drawer.toggle()"></app-header>
    <div class="content">
      <ng-content></ng-content>
    </div>
    <app-footer>
    </app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
