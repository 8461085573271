import {Component, OnInit} from '@angular/core';
import {BaseInfoPageComponent} from '../../core/base-classes/base-info-page.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {DtoOutGetMessage, DtoOutProject, MessageService, ProjectService} from '../../generated';
import {Role} from '../../core/role';
import {ConfigRoutes} from '../../core/config/configRoutes';
import {ConfirmationDialogService} from '../../core/services/confirmation-dialog.service';
import {NotificationsService} from '../../core/services/notifications.service';
import {NavService} from '../../core/structure/content/menu-list-item/nav.service';
import {MatDialog} from '@angular/material/dialog';
import {EditProjectSettingsDialogComponent} from '../../dialogs/edit-project-settings-dialog/edit-project-settings-dialog.component';
import {MessageDataService} from '../../services/message-data.service';
import {MessagesFilterService} from '../../lists/project-messages-list/messages-filter.service';
import {ApplicationInfoService} from '../../services/application-info.service';

@Component({
  selector: 'app-detail-project',
  templateUrl: './detail-project.component.html',
  styleUrls: ['./detail-project.component.scss']
})
export class DetailProjectComponent extends BaseInfoPageComponent implements OnInit {
  public project: DtoOutProject;
  public Role = Role;
  public messages: DtoOutGetMessage[] = [];

  constructor(private title: Title, private activatedRoute: ActivatedRoute,
              private projectService: ProjectService,
              private router: Router,
              private confirmationDialogService: ConfirmationDialogService,
              private navService: NavService,
              private notificationService: NotificationsService,
              private dialog: MatDialog,
              private messageService: MessageService, private messageDataService: MessageDataService,
              private messageFilterService: MessagesFilterService, private applicationInfoService: ApplicationInfoService) {
    super(title, 'Detail projektu', activatedRoute);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getItem(id: number): void {
    this.applicationInfoService.selectedIdProject = id;
    this.projectService.apiProjectGetProjectIdGet(id).subscribe(data => {
      this.project = data.data;
      this.title.setTitle('NotificationService Detail projektu - ' + this.project.name);
    });
    this.getMessagesWithFilters(id);

    this.messageDataService.messageChanged.subscribe(() => {
      this.getMessagesWithFilters(id);
    });
  }

  getMessagesWithFilters(id: number): void {
    // tslint:disable-next-line:max-line-length
    this.messageService.apiMessageGetMessagesForProjectIdProjectGet(id,
      this.messageFilterService.pageFrom, this.messageFilterService.pageSize,
      this.messageFilterService.onlyMyMessages, this.messageFilterService.idSolver,
      this.messageFilterService.senderName, this.messageFilterService.subject, this.messageFilterService.inProgress, this.messageFilterService.isSolved).subscribe(data => {
      this.messages = data.data.messages;
      this.messageFilterService.size = data.data.count;
    });
  }

  refreshMessages() {
    this.getMessagesWithFilters(this.searchedDataId);
  }

  editProject(): void {
    this.router.navigate([ConfigRoutes.editProjectPage, this.searchedDataId]);
  }

  deleteProject(): void {
    this.confirmationDialogService.confirmDialog('Opravdu chcete smazat projekt: ' + this.project.name + ' ?').subscribe(data => {
      if (data) {
        this.doDeleteProject();
      }
    });
  }

  openSettings(): void {
    this.dialog.open(EditProjectSettingsDialogComponent, {data: {id: this.searchedDataId, projectName: this.project?.name}});
  }

  private doDeleteProject(): void {
    this.projectService.apiProjectDeleteProjectIdPost(this.searchedDataId).subscribe(data => {
      this.navService.projectsChanged.next();
      this.notificationService.ok('Projetk byl úspěšně smazán.');
      this.router.navigate([ConfigRoutes.home]);
    }, error => {
      this.notificationService.error('Projekt se nepodařilo smazat');
    });
  }
}
