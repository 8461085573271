<form [formGroup]="form" (ngSubmit)="formSubmit()">
  <mat-form-field validationMessage>
    <mat-label>Vyberte uživatele</mat-label>
    <mat-select formControlName="user">
      <mat-select-trigger>
        <img [src]="getUserUrlImage(form.controls.user.value)" width="20px" height="20px"/>    {{getUserName(form.controls.user.value)}}
      </mat-select-trigger>
      <mat-option [value]="item?.id" *ngFor="let item of users"><img [src]="getUrl(item?.pictureUrl)" width="20px"
                                                                     height="20px"
                                                                     [alt]="item?.lastName"/>{{item?.firstName}} {{item?.lastName}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-raised-button type="submit" [disabled]="btnDisabled">Uložit</button>
</form>
