import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DtoInSetMessageTo, DtoOutUsers, MessageService} from '../../generated';
import {BaseEditFormComponent} from '../../core/base-classes/base-edit-form.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationsService} from '../../core/services/notifications.service';

@Component({
  selector: 'app-set-person-to-message-form',
  templateUrl: './set-person-to-message-form.component.html',
  styleUrls: ['./set-person-to-message-form.component.scss']
})
export class SetPersonToMessageFormComponent implements OnInit {
  get messageId(): number {
    return this._messageId;
  }

  @Input()
  set messageId(value: number) {
    this._messageId = value;
    this.getIdUser();
  }

  @Input()
  public users: Array<DtoOutUsers> = [];

  @Output()
  public formSubmited: EventEmitter<void> = new EventEmitter<void>();

  private _messageId: number;
  public form: FormGroup;
  public userId: number = null;
  public btnDisabled = false;

  constructor(private messageService: MessageService, private notificationsService: NotificationsService) {
  }


  ngOnInit(): void {
    if (this.messageId) {
      this.getIdUser();
    }
    this.createForm();
  }

  getIdUser(): void {
    this.messageService.apiMessageGetUserIdByMessageIdGet(this._messageId).subscribe(data => {
      this.userId = data.data.id;
      this.createForm();
    });
  }

  createForm(): void {
    this.form = new FormGroup({
      'user': new FormControl(this.userId, [Validators.required])
    });
  }

  formSubmit(): void {
    if (this.form.valid) {
      this.btnDisabled = true;
      let user: DtoInSetMessageTo = {idApplicationUser: this.form.controls.user.value, idMessage: this.messageId};
      this.messageService.apiMessageSetMessageToUserPost(user).subscribe(data => {
        this.notificationsService.ok('Uživatel nastaven k zprávě');
        this.btnDisabled = false;
        this.formSubmited.emit();
      }, error => {
        this.notificationsService.error('Uživatele se nepodažilo nastavit k zprávě.');
        this.btnDisabled = false;
      });
    }
  }

  getUrl(url: string): string {
    return new URL(url).toString();
  }

  getUserName(id: number): string {
    if (id && this.users) {
      const user = this.users?.find(x => x.id === id);
      return user?.firstName + ' ' + user?.lastName;
    }
    return null;
  }

  getUserUrlImage(id: number): string {
    if (id && this.users) {
      return this.getUrl(this.users?.find(x => x.id === id)?.pictureUrl);
    }
    return null;
  }


}
