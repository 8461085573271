import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthModule, LogLevel, OidcConfigService} from 'angular-auth-oidc-client';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CoreModule} from './core/core.module';
import {ConfigurationService} from './core/configuration/configuration.service';
import {TokenInterceptor} from './core/TokenInterceptor';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {getCzechPaginatorIntl} from './core/material/myMatPaginator';
import {ApiModule} from './generated';
import {HomePageComponent} from './pages/home-page/home-page.component';
import {CreateProjectComponent} from './pages/create-project/create-project.component';
import {EditProjectComponent} from './pages/edit-project/edit-project.component';
import {DetailProjectComponent} from './pages/detail-project/detail-project.component';
import {CreateEditProjectFormComponent} from './forms/create-edit-project-form/create-edit-project-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FlexModule} from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {HttpSpinnerModule} from 'http-spinner';
import {EditProjectSettingsFormComponent} from './forms/edit-project-settings-form/edit-project-settings-form.component';
import {EditProjectSettingsDialogComponent} from './dialogs/edit-project-settings-dialog/edit-project-settings-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ProjectMessagesListComponent} from './lists/project-messages-list/project-messages-list.component';
import {SetPersonToMessageDialogComponent} from './dialogs/set-person-to-message-dialog/set-person-to-message-dialog.component';
import {SetPersonToMessageFormComponent} from './forms/set-person-to-message-form/set-person-to-message-form.component';
import {MatSelectModule} from '@angular/material/select';
import {MessagesFilterComponent} from './lists/project-messages-list/messages-filter/messages-filter.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MessageDetailPageComponent} from './pages/message-detail-page/message-detail-page.component';
import {EmployeesPageComponent} from './pages/employees-page/employees-page.component';
import {EmployeeListComponent} from './lists/employee-list/employee-list.component';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {EmployeePageComponent} from './pages/employee-page/employee-page.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AddUserToProjectFormComponent} from './forms/add-user-to-project-form/add-user-to-project-form.component';
import {AddUserToProjectDialogComponent} from './dialogs/add-user-to-project-dialog/add-user-to-project-dialog.component';

export function configureAuth(oidcConfigService: OidcConfigService) {
  return () =>
    oidcConfigService.withConfig({
      stsServer: 'https://oidc.larvasystems.cz',
      redirectUrl: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
      clientId: 'NotificationServiceClientJs',
      scope: 'openid profile UsersApi NotificationServiceApi offline_access',
      responseType: 'code',
      silentRenew: true,
      useRefreshToken: true,
      silentRenewUrl: `${window.location.origin}/silent-renew.html`,
      logLevel: LogLevel.Error,
    });
}

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    CreateProjectComponent,
    EditProjectComponent,
    DetailProjectComponent,
    CreateEditProjectFormComponent,
    EditProjectSettingsFormComponent,
    EditProjectSettingsDialogComponent,
    ProjectMessagesListComponent,
    SetPersonToMessageDialogComponent,
    SetPersonToMessageFormComponent,
    MessagesFilterComponent,
    MessageDetailPageComponent,
    EmployeesPageComponent,
    EmployeeListComponent,
    EmployeePageComponent,
    AddUserToProjectFormComponent,
    AddUserToProjectDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthModule.forRoot(),
    CoreModule,
    ApiModule.forRoot(ConfigurationService.getConfigForGenerated),
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FlexModule,
    MatCardModule,
    MatButtonModule,
    MatMenuModule,
    HttpSpinnerModule,
    MatDialogModule,
    MatTableModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatExpansionModule,
    FormsModule,
    MatPaginatorModule,
    MatSortModule,
    MatTooltipModule
  ],
  providers: [
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService],
      multi: true,
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {provide: MatPaginatorIntl, useValue: getCzechPaginatorIntl()},
    {
      provide: LOCALE_ID, useValue: 'cs'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
