import {Component, Inject, OnInit} from '@angular/core';
import {DtoOutProjectSettings, ProjectService} from '../../generated';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-edit-project-settings-dialog',
  templateUrl: './edit-project-settings-dialog.component.html',
  styleUrls: ['./edit-project-settings-dialog.component.scss']
})
export class EditProjectSettingsDialogComponent implements OnInit {
  public projectSettings: DtoOutProjectSettings;
  public disabled = false;
  constructor(private projectService: ProjectService, public dialogRef: MatDialogRef<EditProjectSettingsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { id: number, projectName: string }) {
  }

  ngOnInit(): void {

    this.projectService.apiProjectGetProjectSettingsIdGet(this.data.id).subscribe(data => {
      this.projectSettings = data.data;
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
