import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatDialogModule} from '@angular/material/dialog';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatCardModule} from '@angular/material/card';
import {RouterModule} from '@angular/router';
import {MatFormFieldModule} from '@angular/material/form-field';
import {HttpSpinnerModule, InterceptorService} from 'http-spinner';
import {ReactiveFormsModule} from '@angular/forms';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {FlexModule} from '@angular/flex-layout';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {getCzechPaginatorIntl} from './material/myMatPaginator';
import {ConfirmationDialogComponent} from './dialogs/confirmation-dialog/confirmation-dialog.component';
import {CardWrapperComponent} from './card-wrapper/card-wrapper.component';
import {UnauthorizedPageComponent} from './unauthorized-page/unauthorized-page.component';
import {CoreRoutingModule} from './core-routing.module';
import {FooterComponent} from './structure/footer/footer.component';
import {HeaderComponent} from './structure/header/header.component';
import {ContentComponent} from './structure/content/content.component';
import {MenuListItemComponent} from './structure/content/menu-list-item/menu-list-item.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatRippleModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import { AuthorizationDirective } from './directives/authorization.directive';
import { ValidationDirective } from './directives/validation.directive';
import { ValidationErrorComponent } from './directives/validation-error/validation-error.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import { RedirectComponentComponent } from './components/redirect-component/redirect-component.component';


@NgModule({
    exports: [ConfirmationDialogComponent, CardWrapperComponent, CoreRoutingModule, ContentComponent, MatSidenavModule,
        MatRippleModule,
        MatIconModule,
        MatToolbarModule,
        MatListModule, AuthorizationDirective, ValidationDirective, RedirectComponentComponent],
  declarations: [ConfirmationDialogComponent, CardWrapperComponent, UnauthorizedPageComponent, FooterComponent, HeaderComponent, ContentComponent, MenuListItemComponent, AuthorizationDirective, ValidationDirective, ValidationErrorComponent, RedirectComponentComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreRoutingModule,
    MatDialogModule,
    HttpClientModule,
    MatCardModule,
    RouterModule,
    MatFormFieldModule,
    HttpSpinnerModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatInputModule,
    MatButtonModule,
    FlexModule,
    MatSidenavModule,
    MatRippleModule,
    MatIconModule,
    MatToolbarModule,
    MatListModule
  ],
  providers: [{provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 5000, verticalPosition: 'top'}},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }, {
      provide: MatPaginatorIntl,
      useValue: getCzechPaginatorIntl()
    }]
})
export class CoreModule {
}
