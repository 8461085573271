<http-spinner>
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Filter" #input>
  </mat-form-field>
  <div class=" table-responsive">

    <table mat-table [dataSource]="dataSource" matSort class="full-width-table">

      <!-- Position Column -->
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Jméno</th>
        <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Příjmení</th>
        <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="picture">
        <th mat-header-cell *matHeaderCellDef> Fotka</th>
        <td mat-cell *matCellDef="let element"><img [src]="getUrl(element?.pictureUrl)" width="20px" height="20px"/>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="clickable" *matRowDef="let row; columns: displayedColumns;" (click)="onRowCLick(row?.id)"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">K dispozici nejsou žádná data</td>
      </tr>
    </table>
    <mat-paginator #paginator
                   [length]="dataSource?.data?.length"
                   [pageIndex]="0"
                   [pageSize]="10"
                   [pageSizeOptions]="[10,25, 50]">
    </mat-paginator>
  </div>
</http-spinner>
