/**
 * Notification service
 * Notification service for larvasystems products contact forms
 *
 * OpenAPI spec version: 1
 * Contact: vojtech.stor@larvasystems.cz
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DtoInSendMessage } from '../model/dtoInSendMessage';
import { DtoInSetMessageTo } from '../model/dtoInSetMessageTo';
import { DtoOutGetMessageMapResponseObject } from '../model/dtoOutGetMessageMapResponseObject';
import { DtoOutPaginationMessagesMapResponseObject } from '../model/dtoOutPaginationMessagesMapResponseObject';
import { DtoOutUserIdMapResponseObject } from '../model/dtoOutUserIdMapResponseObject';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MessageService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMessageDeleteMessageIdDelete(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiMessageDeleteMessageIdDelete(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiMessageDeleteMessageIdDelete(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiMessageDeleteMessageIdDelete(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiMessageDeleteMessageIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/Message/deleteMessage/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMessageGetMessageIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<DtoOutGetMessageMapResponseObject>;
    public apiMessageGetMessageIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DtoOutGetMessageMapResponseObject>>;
    public apiMessageGetMessageIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DtoOutGetMessageMapResponseObject>>;
    public apiMessageGetMessageIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiMessageGetMessageIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DtoOutGetMessageMapResponseObject>('get',`${this.basePath}/api/Message/getMessage/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param idProject 
     * @param pageFrom 
     * @param count 
     * @param onlyMyMessages 
     * @param idSolver 
     * @param senderName 
     * @param subject 
     * @param inProgress 
     * @param isSolved 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMessageGetMessagesForProjectIdProjectGet(idProject: number, pageFrom?: number, count?: number, onlyMyMessages?: boolean, idSolver?: number, senderName?: string, subject?: string, inProgress?: boolean, isSolved?: boolean, observe?: 'body', reportProgress?: boolean): Observable<DtoOutPaginationMessagesMapResponseObject>;
    public apiMessageGetMessagesForProjectIdProjectGet(idProject: number, pageFrom?: number, count?: number, onlyMyMessages?: boolean, idSolver?: number, senderName?: string, subject?: string, inProgress?: boolean, isSolved?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DtoOutPaginationMessagesMapResponseObject>>;
    public apiMessageGetMessagesForProjectIdProjectGet(idProject: number, pageFrom?: number, count?: number, onlyMyMessages?: boolean, idSolver?: number, senderName?: string, subject?: string, inProgress?: boolean, isSolved?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DtoOutPaginationMessagesMapResponseObject>>;
    public apiMessageGetMessagesForProjectIdProjectGet(idProject: number, pageFrom?: number, count?: number, onlyMyMessages?: boolean, idSolver?: number, senderName?: string, subject?: string, inProgress?: boolean, isSolved?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idProject === null || idProject === undefined) {
            throw new Error('Required parameter idProject was null or undefined when calling apiMessageGetMessagesForProjectIdProjectGet.');
        }









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pageFrom !== undefined && pageFrom !== null) {
            queryParameters = queryParameters.set('pageFrom', <any>pageFrom);
        }
        if (count !== undefined && count !== null) {
            queryParameters = queryParameters.set('count', <any>count);
        }
        if (onlyMyMessages !== undefined && onlyMyMessages !== null) {
            queryParameters = queryParameters.set('onlyMyMessages', <any>onlyMyMessages);
        }
        if (idSolver !== undefined && idSolver !== null) {
            queryParameters = queryParameters.set('idSolver', <any>idSolver);
        }
        if (senderName !== undefined && senderName !== null) {
            queryParameters = queryParameters.set('senderName', <any>senderName);
        }
        if (subject !== undefined && subject !== null) {
            queryParameters = queryParameters.set('subject', <any>subject);
        }
        if (inProgress !== undefined && inProgress !== null) {
            queryParameters = queryParameters.set('inProgress', <any>inProgress);
        }
        if (isSolved !== undefined && isSolved !== null) {
            queryParameters = queryParameters.set('isSolved', <any>isSolved);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DtoOutPaginationMessagesMapResponseObject>('get',`${this.basePath}/api/Message/getMessagesForProject/${encodeURIComponent(String(idProject))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMessageGetUserIdByMessageIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<DtoOutUserIdMapResponseObject>;
    public apiMessageGetUserIdByMessageIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DtoOutUserIdMapResponseObject>>;
    public apiMessageGetUserIdByMessageIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DtoOutUserIdMapResponseObject>>;
    public apiMessageGetUserIdByMessageIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiMessageGetUserIdByMessageIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DtoOutUserIdMapResponseObject>('get',`${this.basePath}/api/Message/GetUserIdByMessage/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMessageSendMessagePost(body?: DtoInSendMessage, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiMessageSendMessagePost(body?: DtoInSendMessage, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiMessageSendMessagePost(body?: DtoInSendMessage, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiMessageSendMessagePost(body?: DtoInSendMessage, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Message/sendMessage`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param idMessage 
     * @param inProgress 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMessageSetMessageToInProgressIdMessageGet(idMessage: number, inProgress?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiMessageSetMessageToInProgressIdMessageGet(idMessage: number, inProgress?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiMessageSetMessageToInProgressIdMessageGet(idMessage: number, inProgress?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiMessageSetMessageToInProgressIdMessageGet(idMessage: number, inProgress?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idMessage === null || idMessage === undefined) {
            throw new Error('Required parameter idMessage was null or undefined when calling apiMessageSetMessageToInProgressIdMessageGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (inProgress !== undefined && inProgress !== null) {
            queryParameters = queryParameters.set('inProgress', <any>inProgress);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Message/setMessageToInProgress/${encodeURIComponent(String(idMessage))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMessageSetMessageToMePost(body?: DtoInSetMessageTo, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiMessageSetMessageToMePost(body?: DtoInSetMessageTo, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiMessageSetMessageToMePost(body?: DtoInSetMessageTo, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiMessageSetMessageToMePost(body?: DtoInSetMessageTo, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Message/SetMessageToMe`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param idMessage 
     * @param solved 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMessageSetMessageToSolvedIdMessageGet(idMessage: number, solved?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiMessageSetMessageToSolvedIdMessageGet(idMessage: number, solved?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiMessageSetMessageToSolvedIdMessageGet(idMessage: number, solved?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiMessageSetMessageToSolvedIdMessageGet(idMessage: number, solved?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idMessage === null || idMessage === undefined) {
            throw new Error('Required parameter idMessage was null or undefined when calling apiMessageSetMessageToSolvedIdMessageGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (solved !== undefined && solved !== null) {
            queryParameters = queryParameters.set('solved', <any>solved);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Message/setMessageToSolved/${encodeURIComponent(String(idMessage))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMessageSetMessageToUserPost(body?: DtoInSetMessageTo, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiMessageSetMessageToUserPost(body?: DtoInSetMessageTo, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiMessageSetMessageToUserPost(body?: DtoInSetMessageTo, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiMessageSetMessageToUserPost(body?: DtoInSetMessageTo, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Message/SetMessageToUser`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param idMessage 
     * @param visibility 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMessageSetMessageVisibilityIdMessagePost(idMessage: number, visibility?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiMessageSetMessageVisibilityIdMessagePost(idMessage: number, visibility?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiMessageSetMessageVisibilityIdMessagePost(idMessage: number, visibility?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiMessageSetMessageVisibilityIdMessagePost(idMessage: number, visibility?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idMessage === null || idMessage === undefined) {
            throw new Error('Required parameter idMessage was null or undefined when calling apiMessageSetMessageVisibilityIdMessagePost.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (visibility !== undefined && visibility !== null) {
            queryParameters = queryParameters.set('visibility', <any>visibility);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Message/setMessageVisibility/${encodeURIComponent(String(idMessage))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
