<mat-card>
  <mat-card-header>
    <div mat-card-avatar class="image-cover" *ngIf="user?.pictureUrl"
         [ngStyle]="{'background-image':'url('+getUrl(user?.pictureUrl)+')'}"></div>
    <mat-card-title>
      {{user?.firstName}} {{user?.lastName}}
    </mat-card-title>
    <mat-card-subtitle>{{user?.email}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <button mat-raised-button (click)="addUserToProject()">Přidat do projektu</button>
    <div>
      Projekty, ve kterých je obsazen:
      <mat-list>
        <mat-list-item *ngFor="let item of user?.projectLists"
                       [matTooltip]="item?.description"
                       (click)="redirectToProject(item?.id)" class="clickable">{{item?.name}}
          <button mat-icon-button color="warn" (click)="removeUserFromProject(item?.id);$event.stopPropagation()">
            <mat-icon>remove</mat-icon>
          </button>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-card-content>
</mat-card>
