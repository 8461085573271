<div class="confirmation-dialog">
  <h1 mat-dialog-title>{{data}}</h1>
  <div mat-dialog-content>

  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" cdkFocusInitial (click)="ok()">Ano</button>
    <button mat-raised-button color="warn" (click)="no()">Zrušit</button>

  </div>
</div>
