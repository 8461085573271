import {Component, OnInit} from '@angular/core';
import {ApplicationUserService, DtoOutAllApplicationUsers} from '../../generated';

@Component({
  selector: 'app-employees-page',
  templateUrl: './employees-page.component.html',
  styleUrls: ['./employees-page.component.scss']
})
export class EmployeesPageComponent implements OnInit {
  public users: Array<DtoOutAllApplicationUsers> = [];

  constructor(private applicationUserService: ApplicationUserService) {
  }

  ngOnInit(): void {
    this.applicationUserService.apiApplicationUserGetAllUsersGet().subscribe(data => {
      this.users = data.data;
    });
  }

}
