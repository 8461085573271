/**
 * Notification service
 * Notification service for larvasystems products contact forms
 *
 * OpenAPI spec version: 1
 * Contact: vojtech.stor@larvasystems.cz
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DtoInSendMessage { 
    projectGuid?: string;
    firstname?: string;
    lastname?: string;
    email?: string;
    subject?: string;
    messageText?: string;
}