import {Component, OnInit, ViewChild} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {NavItem} from './menu-list-item/NavItem';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {MatSidenavContainer} from '@angular/material/sidenav';
import {ConfigRoutes} from '../../config/configRoutes';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Role} from '../../role';
import {ProjectService} from '../../../generated';
import {NavService} from './menu-list-item/nav.service';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  get fullName(): Observable<string> {
    return this.oidcSecurityService.userData$.pipe(map(data => {
      if (Array.isArray(data?.name)) {
        return data?.name[0];
      }
      return data?.name;
    }));
  }

  public ConfigRoutes = ConfigRoutes;
  @ViewChild('navcontainer', {static: false}) navcontainer: MatSidenavContainer;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
    .pipe(
      map(result => result.matches)
    );

  navItems: NavItem[] = [];

  constructor(private breakpointObserver: BreakpointObserver, public oidcSecurityService: OidcSecurityService, private authService: AuthService, private projectService: ProjectService, private navService: NavService) {
  }


  ngOnInit(): void {
    this.oidcSecurityService.isAuthenticated$.subscribe((isAuthenticated: boolean) => {
      if (isAuthenticated === true) {
        const oldthis = this;
        this.loadNav();
        this.navService.projectsChanged.subscribe(() => {
          oldthis.loadNav();
        });
      }
    });
  }

  loadNav(): void {
    this.navItems = [];
    this.authService.hasRole(Role.NotificationServiceAdmin).subscribe(data => {
      if (data) {
        this.navItems.push({
          displayName: 'Vytvořit projekt',
          iconName: 'business_center',
          role: [Role.NotificationServiceAdmin.toString()],
          route: ConfigRoutes.createProjectPage
        });
        this.navItems.push({
          displayName: 'Zaměstnanci',
          iconName: 'people',
          role: [Role.NotificationServiceAdmin.toString()],
          route: ConfigRoutes.employeePage
        });
      }
    });

    this.projectService.apiProjectGetMyProjectsGet().subscribe(data => {
      data.data.forEach(item => {
        this.navItems.push({
          displayName: item?.name,
          iconName: 'chat_bubble',
          role: [Role.NotificationServiceUser.toString()],
          route: ConfigRoutes.projectPage + '/' + item?.id
        });
      });
    });
  }
}
