import {Title} from '@angular/platform-browser';
import {Page} from '../interface/page';
import {Component, Directive, Inject} from '@angular/core';

@Component({template: ''})
export abstract class BasePageComponent implements Page {
  constructor(private titleService: Title, @Inject(String) public titleString: string) {
    this.titleService.setTitle(this.titleString);
  }
}
