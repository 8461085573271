<mat-card >
  <mat-card-header>
    <mat-card-title>Vytvořit projekt</mat-card-title>
    <mat-card-subtitle>Tento projekt je určen k vytvoření notifikační a emailové funkce k vašemu webu.
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <app-create-edit-project-form [edit]="false"></app-create-edit-project-form>
  </mat-card-content>
</mat-card>
