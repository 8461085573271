import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ConfigRoutes} from '../../config/configRoutes';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {map} from 'rxjs/operators';
import {Role} from '../../role';
import {AuthService} from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private oidcSecurityService: OidcSecurityService, private  router: Router, private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const oldthis = this;
      this.oidcSecurityService.isAuthenticated$.subscribe((isAuthorized: boolean) => {
        console.log('AuthorizationGuard, canActivate isAuthorized: ' + isAuthorized);

        if (!isAuthorized) {
          oldthis.router.navigate(['/' + ConfigRoutes.unauthorizedRoute]);
          observer.next(false);
          observer.complete();
        }
        const roles = route.data.roles as Role[];
        oldthis.authService.getRoles().subscribe(myRoles => {
          if (roles && !roles.some(r => {
            return myRoles.filter(x => x === r).length !== 0;
          })) {
            oldthis.router.navigate(['/' + ConfigRoutes.unauthorizedRoute]);
            observer.next(false);
            observer.complete();
          }
        });
        observer.next(true);
        observer.complete();
      });

    });
  }
}
