import {Component, Inject, OnInit} from '@angular/core';
import {ProjectService} from '../../generated';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-add-user-to-project-dialog',
  templateUrl: './add-user-to-project-dialog.component.html',
  styleUrls: ['./add-user-to-project-dialog.component.scss']
})
export class AddUserToProjectDialogComponent implements OnInit {
  public disabled: boolean;

  constructor(private projectService: ProjectService, public dialogRef: MatDialogRef<AddUserToProjectDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { idUser: number, firstName: string, lastName: string }) {
  }

  ngOnInit(): void {
  }

  close(changed: boolean = false): void {
    this.dialogRef.close(changed);
  }
}
