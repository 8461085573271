<div>
  <form [formGroup]="form" (ngSubmit)="formSubmit()">
    <input type="hidden" formControlName="idProject">
    <mat-form-field validationMessage>
      <input formControlName="contactEmails" matInput placeholder="Kontaktní emaily oddělené čárkou"
             name="Contact emails"
             aria-describedby="Contact emails" required>
    </mat-form-field>
    <mat-slide-toggle formControlName="wantSendEmails">
      Chcete dostávat u pozornění?
    </mat-slide-toggle>
    <ng-content></ng-content>
  </form>
</div>
