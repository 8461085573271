<mat-card>
  <mat-card-header>
    <mat-card-title>{{message?.subject}}   <mat-icon *ngIf="message?.isSeenByMe">visibility</mat-icon>
      <mat-icon *ngIf="!message?.isSeenByMe">visibility_off</mat-icon>
      <mat-icon *ngIf="message?.inProgress">autorenew</mat-icon>
      <mat-icon *ngIf="message?.solved">done</mat-icon>
      <span *ngIf="message?.selectedPerson!==null">
        přiřazeno: {{message?.selectedPerson?.firstName}} {{message?.selectedPerson?.lastName}} <img width="30px"
                                                                                               height="30px"
                                                                                               [src]="message?.selectedPerson?.pictureUrl"/></span></mat-card-title>
    <mat-card-subtitle>{{message?.email}} {{message?.firstname}} {{message?.lastname}}</mat-card-subtitle>
    <span class="menu"><button mat-icon-button [matMenuTriggerFor]="menuList"><mat-icon>more_vert</mat-icon></button></span>
  </mat-card-header>
  <mat-card-content>
    {{message?.messageText}}
  </mat-card-content>
</mat-card>
<mat-menu #menuList="matMenu">
  <button *hasAccessToView="[Role.NotificationServiceAdmin]" mat-menu-item (click)="addPerson()">
    <mat-icon>perm_identity</mat-icon>
    <span>Přiřadit osobu</span>
  </button>
  <button *hasAccessToView="[Role.NotificationServiceAdmin]" mat-menu-item (click)="deleteMessage()">
    <mat-icon color="warn">delete</mat-icon>
    <span>Odstranit</span>
  </button>
  <button *ngIf="message?.isSeenByMe===true" mat-menu-item (click)="setVisibility(false)">
    <mat-icon>visibility_off</mat-icon>
    <span>Označit jako neviděná</span>
  </button>
  <button *ngIf="message?.isSeenByMe===false" mat-menu-item (click)="setVisibility(true)">
    <mat-icon>visibility</mat-icon>
    <span>Označit jako viděno</span>
  </button>
  <button *ngIf="message?.inProgress===false" mat-menu-item (click)="setStateToInProgress()">
    <mat-icon>autorenew</mat-icon>
    <span>Posunout do stavu: V řešení</span>
  </button>
  <button *ngIf="message?.solved===false" mat-menu-item (click)="setStateToSolved()">
    <mat-icon>done</mat-icon>
    <span>Posunout do stavu: Vyřešeno</span>
  </button>
</mat-menu>
