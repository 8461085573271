import {Observable} from 'rxjs';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import {ConfigRoutes} from './config/configRoutes';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private auth: OidcSecurityService, private  router: Router, private snack: MatSnackBar) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getToken()}`
      }
    });
    return next.handle(request).pipe(tap(() => {
      },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401 && err.status !== 403) {
            return;
          }
          this.snack.open('Na tuto stránku nemáte oprávnění', 'ok', {panelClass: ['red-snackbar']});
          this.router.navigate([ConfigRoutes.unauthorizedRoute]);
        }
      }));
  }
}
