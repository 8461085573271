import {Component, OnInit} from '@angular/core';
import {BasePageComponent} from "../base-classes/base-page.component";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-unauthorized-page',
  templateUrl: './unauthorized-page.component.html',
  styleUrls: ['./unauthorized-page.component.scss']
})
export class UnauthorizedPageComponent extends BasePageComponent implements OnInit {

  constructor(private title: Title) {
    super(title, 'Zakázaný přístup');
  }

  ngOnInit(): void {
  }

}
