import {Component, OnInit} from '@angular/core';
import {BaseInfoPageComponent} from '../../core/base-classes/base-info-page.component';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {ApplicationUserService, DtoOutGetMessage, MessageService, ProjectService} from '../../generated';
import {ConfirmationDialogService} from '../../core/services/confirmation-dialog.service';
import {NotificationsService} from '../../core/services/notifications.service';
import {MatDialog} from '@angular/material/dialog';
import {Role} from 'src/app/core/role';
import {MessageDataService} from '../../services/message-data.service';
import {SetPersonToMessageDialogComponent} from '../../dialogs/set-person-to-message-dialog/set-person-to-message-dialog.component';
import {ApplicationInfoService} from '../../services/application-info.service';
import {ConfigRoutes} from '../../core/config/configRoutes';

@Component({
  selector: 'app-message-detail-page',
  templateUrl: './message-detail-page.component.html',
  styleUrls: ['./message-detail-page.component.scss']
})
export class MessageDetailPageComponent extends BaseInfoPageComponent implements OnInit {
  public message: DtoOutGetMessage;
  public Role = Role;

  constructor(private title: Title, private activatedRoute: ActivatedRoute,
              private router: Router,
              private messageService: MessageService, private confirmationDialogService: ConfirmationDialogService,
              private messageDataService: MessageDataService,
              private  notifficationsService: NotificationsService, private matDialog: MatDialog, private applicationInfoService: ApplicationInfoService) {
    super(title, 'Detail zprávy', activatedRoute);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getItem(id: number): void {
    this.messageService.apiMessageGetMessageIdGet(id).subscribe(data => {
      this.message = data.data;
      this.applicationInfoService.selectedIdProject = this.message.idProject;
    });
  }

  addPerson(): void {
    const dialog = this.matDialog.open(SetPersonToMessageDialogComponent, {data: {idMessage: this.searchedDataId}});
    dialog.afterClosed().subscribe(data => {
      if (data) {
        this.getItem(this.searchedDataId);
      }
    });
  }

  deleteMessage(): void {
    this.confirmationDialogService.confirmDialog('Opravdu chcete odstranit zprávu?').subscribe(data => {
      if (data === true) {
        this.doDeleteMessage();
      }
    });
  }

  doDeleteMessage(): void {
    this.messageService.apiMessageDeleteMessageIdDelete(this.searchedDataId).subscribe(data => {
      this.notifficationsService.ok('zprávu se podařilo odstranit.');
      this.router.navigate([ConfigRoutes.projectPage, this.message.idProject]);
    }, error => {
      this.notifficationsService.error('Zprávu se nepodařilo smazat.');
    });
  }

  setVisibility(visibility: boolean): void {
    this.messageService.apiMessageSetMessageVisibilityIdMessagePost(this.searchedDataId, visibility).subscribe(data => {
      this.notifficationsService.ok('Stav byl nastaven úspěšně');
      this.message.isSeenByMe = visibility;
    }, error => {
      this.notifficationsService.ok('Stav se nepodařilo nastavit');
    });
  }

  setStateToInProgress(): void {
    this.messageService.apiMessageSetMessageToInProgressIdMessageGet(this.searchedDataId).subscribe(data => {
      this.notifficationsService.ok('Stav byl posunut do řešení.');
      this.message.inProgress = true;
      this.message.solved = false;
    }, error => {
      this.notifficationsService.ok('Stav se nepodařilo nastavit');
    });
  }

  setStateToSolved(): void {
    this.messageService.apiMessageSetMessageToSolvedIdMessageGet(this.searchedDataId).subscribe(data => {
      this.notifficationsService.ok('Stav byl posunut do dokončeno.');
      this.message.inProgress = false;
      this.message.solved = true;
    }, error => {
      this.notifficationsService.error('Stav se nepodařilo nastavit');
    });
  }
}
